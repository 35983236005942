import React, { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import withSuspense from "./HOC/WithSupense";
import MileStoneCreate from "./Pages/MileStoneCreate";
import MileStoneEdit from "./Pages/MilestoneEdit";
import Approved from "./Pages/Approved";
import Delevered from "./Pages/Delivered";
import TobeApprove from "./Pages/TobeApprove";

// Lazy load components
const LoginPage = lazy(() => import("./Pages/Login"));
const HomePage = lazy(() => import("./Pages/Home"));
const DashBoardLayout = lazy(() => import("./Components/DashBoard/DashBoardLayout"));
const InvoicingPage = lazy(() => import("./Pages/Invoice"));
const GSTRPage = lazy(() => import("./Pages/GSTR"));
const NDCPage = lazy(() => import("./Pages/NDC"));
const TDSPage = lazy(() => import("./Pages/TDS"));
const LUTPage = lazy(() => import("./Pages/LUT"));
const SubmitInvoicePage = lazy(() => import("./Components/InvoiceComps/SubmitInvoice"));
const EditInvoicePage = lazy(() => import("./Components/InvoiceComps/EditInvoice."));
const InvoiceSearchPage = lazy(() => import("./Components/InvoiceComps/InvoiceSearch"));
const InvoiceReportPage = lazy(() => import("./Components/InvoiceComps/InvoiceReport"));
const VendorInvoiceEntryPage = lazy(() => import("./Components/InvoiceComps/VendorInvoiceEntry"));
const AddNDCPage = lazy(() => import("./Components/NDCComps/AddNDC"));
const ViewNDCPage = lazy(() => import("./Components/NDCComps/ViewNDC"));
const EditInvoiceEntryPage = lazy(() => import("./Components/InvoiceComps/EditInvoiceEntry"));
const DashBoardPage = lazy(() => import("./Pages/DashBoard"));
// const ToBeApprove = lazy(() => import("./Pages/PurchaseOrders/ToBeApprove"));
const CreateOrder = lazy(() => import("./Pages/PurchaseOrders/Delivered"));
const Report = lazy(() => import("./Pages/PurchaseOrders/Reports"));

const Create = lazy(() => import("./Pages/CreateOrders"));
const EditOrder = lazy(() => import("./Pages/EditOrder"));
const advanceSearch  = lazy(() => import("./Pages/AdvanceSearch"));

const approve = lazy(() => import("./Pages/Approved"));

const delevered = lazy(() => import("./Pages/Delivered"));

const tobeapprove = lazy(() => import("./Pages/TobeApprove"));

const tobeapprovesubpage = lazy(() => import("./Pages/TobeApprovesubpage"));


const mileStoneCreateSubpage = lazy(() => import("./Pages/MilestoneCreateSubpage"));


export const startUrl = "/";

function Routes({ changeTheme }) {
  const auth = false; // Replace this with your actual auth check

  return useRoutes([
    {
      path: startUrl,
      element: withSuspense(LoginPage)(),
    },
    {
      path: "dashbrdlayout",
      element: !auth ? withSuspense(DashBoardLayout)({ changeTheme }) : <Navigate to={startUrl} />,
      children: [
        {
          path: "dashboard",
          element: withSuspense(DashBoardPage)(),
        },
        {
          path: "invoicing",
          element: withSuspense(InvoicingPage)(),
        },
        {
          path: "gstr",
          element: withSuspense(GSTRPage)(),
        },
        {
          path: "ndc",
          element: withSuspense(NDCPage)(),
        },
        {
          path: "tds",
          element: withSuspense(TDSPage)(),
        },
        {
          path: "lut",
          element: withSuspense(LUTPage)(),
        },
        {
          path: "submitinvoice",
          element: withSuspense(SubmitInvoicePage)(),
        },
        {
          path: "editinvoice",
          element: withSuspense(EditInvoicePage)(),
        },
        {
          path: "invoicesearch",
          element: withSuspense(InvoiceSearchPage)(),
        },
        {
          path: "invoicereport",
          element: withSuspense(InvoiceReportPage)(),
        },
        {
          path: "invoiceentry",
          element: withSuspense(VendorInvoiceEntryPage)(),
        },
        {
          path: "addndc",
          element: withSuspense(AddNDCPage)(),
        },
        {
          path: "viewndc",
          element: withSuspense(ViewNDCPage)(),
        },
        {
          path: "editinvoiceentry",
          element: withSuspense(EditInvoiceEntryPage)(),
        },
        // {
        //   path: "tobeapprove",
        //   element: withSuspense(ToBeApprove)(),
        // },
        // {
        //   path: "approve",
        //   element: withSuspense(Approved)(),
        // },
        // {
        //   path: "deliverd",
        //   element: withSuspense(CreateOrder)(),
        // },
        {
          path: "report",
          element: withSuspense(Report)(),
        },
        {
          path: "create",
          element: withSuspense(Create)(),
        },
        {
          path: "Edit",
          element: withSuspense(EditOrder)(),
        },
        {
          path: "advancesearch",
          element: withSuspense(advanceSearch)(),
        },{
          path: "milestoneCreate",
          element: withSuspense(MileStoneCreate)(),
        },{
          path:"milestoneEdit",
          element: withSuspense(MileStoneEdit)(),
        },{
          path:"approved",
          element: withSuspense(approve)(),
        },
        {
          path:"deliverd",
          element: withSuspense(delevered)(),
        },{
          path:"tobeapprove",
          element: withSuspense(tobeapprove)(),
        },{
          path:"tobeapprovesubpage",
          element: withSuspense(tobeapprovesubpage)(),
        },{
          path:"mileStoneCreateSubpage",
          element: withSuspense(mileStoneCreateSubpage)(),
        }
      

      ],
    },
    {
      path: "*",
      element: <Navigate to={startUrl} />,
    },
  ]);
}

export default Routes;
