import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const MileStoneCreate = () => {
  const handleEdit = (gstNumber) => {
    console.log(`Edit clicked for GST: ${gstNumber}`);
    // Add your logic for editing here
  };

  const vendorData = [
    { gst: '34TRASDF678IOP', name: 'Connecticons', code: '3456789', state: 'Tamilnadu' },
    { gst: '34FGASDF908IOL', name: 'Connecticons', code: '1336789', state: 'Tamilnadu' },
  ];

  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the "tobeapprovesubpage"
    navigate('/dashbrdlayout/mileStoneCreateSubpage');
  };
  


  return (
    <Box sx={{ p: 4 }}>
      {/* Form Section */}
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel>PO Number</InputLabel>
            <Select label="PO Number">
              <MenuItem value=""><em>Select</em></MenuItem>
              <MenuItem value={10}>PO1234</MenuItem>
              <MenuItem value={20}>PO5678</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField label="GST Number" fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField label="Vendor Name" fullWidth />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button variant="contained" color="primary">
            <span role="img" aria-label="Search Icon">🔍</span>
          </Button>
        </Grid>
      </Grid>

      {/* Table Section */}
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>GST Number</TableCell>
              <TableCell>Vendor Name</TableCell>
              <TableCell>Vendor Code</TableCell>
              <TableCell>State/Region</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vendorData.map((row, index) => (
              <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                <TableCell>{row.gst}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.state}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                   sx={{backgroundColor: "#47D276",color:"white",'&:hover': {
      background: "#47D276", // Keeps the button background color unchanged on hover
    },
    '&:focus': {
      outline: 'none', // Optional: Removes focus outline if needed
    },}}
                    onClick={() => handleClick()}
                  >
                    Assign
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MileStoneCreate;
