import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

const MileStoneEdit = () => {
  const handleEdit = (gstNumber) => {
    console.log(`Edit clicked for GST: ${gstNumber}`);
    // Add your logic for editing here
  };

  

  const vendorData = [
    { gst: 1, name: 1, code: '25% Advance', state: '0.25' },
    { gst: 1, name: 1, code: '25% Design Completion', state: '0.25' },
  ];

  return (
    <Box sx={{ p: 4 }}>
      {/* Form Section */}
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel>PO Number</InputLabel>
            <Select label="PO Number">
              <MenuItem value=""><em>Select</em></MenuItem>
              <MenuItem value={10}>PO1234</MenuItem>
              <MenuItem value={20}>PO5678</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField label="GST Number" fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField label="Vendor Name" fullWidth />
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button variant="contained" color="primary">
            <span role="img" aria-label="Search Icon">🔍</span>
          </Button>
        </Grid>
      </Grid>

      {/* Table Section */}
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Line Item No</TableCell>
              <TableCell>MileStone Description</TableCell>
              <TableCell>Quanity</TableCell>
             
            </TableRow>
          </TableHead>
          <TableBody>
            {vendorData.map((row, index) => (
              <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                <TableCell>{row.gst}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.state}</TableCell>
               
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 4 ,display:"flex",justifyContent:"space-between"}}>
      <Button
  variant="contained"
  sx={{
    background: "#FDF6EB",
   
    '&:hover': {
      background: "#FDF6EB", // Keeps the button background color unchanged on hover
    },
    '&:focus': {
      outline: 'none', // Optional: Removes focus outline if needed
    },
  }}
>
  Add Row
</Button>

        <Button
  variant="contained"
  sx={{
    background: "#47D276",
    color:"white",
    '&:hover': {
      background: "#47D276", // Keeps the button background color unchanged on hover
    },
    '&:focus': {
      outline: 'none', // Optional: Removes focus outline if needed
    },
  }}
>
  Save
</Button>

        </Box>
  
    </Box>
  );
};

export default MileStoneEdit;
