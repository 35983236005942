import React from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const TobeApprove = () => {
  const handleEdit = (gstNumber) => {
    console.log(`Edit clicked for GST: ${gstNumber}`);
    // Add your logic for editing here
  };

  const vendorData = [
    { gst: 1, name: 1, code: "25% Advance", state: "0.25" },
    { gst: 1, name: 1, code: "25% Design Completion", state: "0.25" },
  ];

  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the "tobeapprovesubpage"
    navigate("/dashbrdlayout/tobeapprovesubpage");
  };

  return (
    <Box sx={{ p: 4 }}>
      {/* Form Section */}

      {/* Table Section */}
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>GST No</TableCell>
              <TableCell>Vendor Name</TableCell>
              <TableCell>Vendor Code</TableCell>
              <TableCell>State/Region</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vendorData.map((row, index) => (
              <TableRow
                key={index}
                sx={{ backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff" }}
              >
                <TableCell>{row.gst}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.state}</TableCell>
                <TableCell>
                  <Button
                    onClick={handleClick}
                    sx={{
                      backgroundColor: "#47D276",
                      color: "white",
                      "&:hover": {
                        background: "#47D276", // Keeps the button background color unchanged on hover
                      },
                      "&:focus": {
                        outline: "none", // Optional: Removes focus outline if needed
                      },
                    }}
                  >
                    Action
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TobeApprove;
