import React, { Suspense } from "react";
import Spinner from "../../src/Components/Spinner/Spinner";

const withSuspense = (Component) => (props) =>
  (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            transform: "translate(70%, 70%)",
          }}
        >
          <Spinner />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );

export default withSuspense;
