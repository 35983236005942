import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

const Approved = () => {
  const handleEdit = (gstNumber) => {
    console.log(`Edit clicked for GST: ${gstNumber}`);
    // Add your logic for editing here
  };

  const vendorData = [
    { gst: 1, name: 1, code: '25% Advance', state: '0.25' },
    { gst: 1, name: 1, code: '25% Design Completion', state: '0.25' },
  ];

  return (
    <Box sx={{ p: 4 }}>
      {/* Form Section */}
      
      {/* Table Section */}
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Line Item No</TableCell>
              <TableCell>Po No</TableCell>
              <TableCell>Line Item Description</TableCell>
              <TableCell>Quanity</TableCell>
              <TableCell>Rate</TableCell>
             
            </TableRow>
          </TableHead>
          <TableBody>
            {vendorData.map((row, index) => (
              <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                <TableCell>{row.gst}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.state}</TableCell>
                <TableCell>{row.gst}</TableCell>
               
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 4 ,display:"flex",justifyContent:"space-between"}}>
      <Button
  variant="contained"
  sx={{
    background: "#FDF6EB",
   
    '&:hover': {
      background: "#FDF6EB", // Keeps the button background color unchanged on hover
    },
    '&:focus': {
      outline: 'none', // Optional: Removes focus outline if needed
    },
  }}
>
  Add Row
</Button>

        <Button
  variant="contained"
  sx={{
    background: "#47D276",
    color:"white",
    '&:hover': {
      background: "#47D276", // Keeps the button background color unchanged on hover
    },
    '&:focus': {
      outline: 'none', // Optional: Removes focus outline if needed
    },
  }}
>
  Save
</Button>

        </Box>
  
    </Box>
  );
};

export default Approved;
